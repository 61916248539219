import { BaseComponent, Component, Prop } from '../index';

import { Format } from './inline';
import type { IFormatDate } from './FormatDate.d';

/**
 * Formats the date and/or time of received date using the provided format or the store configuration
 * defined format if no format is provided
 */
@Component({})
export class FormatDate extends BaseComponent<IFormatDate, unknown> {
  @Prop() date!: Date | string | number;

  @Prop() format?: string;

  private parseDate(input, format) {
    format = format || 'yyyy-mm-dd';

    const parts = input.match(/(\d+)/g);
    let i = 0;
    const fmt: any = {};

    format.replace(/(yyyy|dd|mm)/g, (part) => {
      fmt[part] = i++;
    });

    return new Date(parts[fmt.yyyy], parts[fmt.mm] - 1, parts[fmt.dd]);
  }

  render() {
    return <span>{Format.date(this.parseDate(this.date, this.format))}</span>;
  }
}
